<template>
  <ListLayout
    icon="mdi-account-multiple"
    title="QUẢN LÝ NGƯỜI DÙNG"
    subTitle="Danh sách người dùng hệ thống"
  >
    <template slot="filter">
      <v-row>
        <v-col :cols="isSysAdmin ? 3 : 4">
          <v-select
            v-model="roleId"
            item-text="description"
            item-value="id"
            :items="roles"
            placeholder="Quyền"
            hide-details
            clearable
            @change="changeRoleSearch"
            outlined
            dense
          ></v-select
        ></v-col>
        <v-col :cols="isSysAdmin ? 3 : 4">
          <v-select
            v-model="trang_thai"
            item-text="name"
            item-value="value"
            :items="trangThais"
            placeholder="Trạng thái"
            hide-details
            clearable
            @change="changeRoleSearch"
            outlined
            dense
          ></v-select
        ></v-col>
        <v-col :cols="isSysAdmin ? 3 : 4" v-if="isSysAdmin">
          <v-select
            v-model="trang_thai_xoa"
            item-text="name"
            item-value="value"
            :items="trangThaiXoas"
            placeholder="Trạng thái xóa"
            hide-details
            clearable
            @change="changeRoleSearch"
            outlined
            dense
          ></v-select
        ></v-col>

        <v-col :cols="isSysAdmin ? 3 : 4">
          <v-text-field
            append-icon="mdi-magnify"
            v-model="search"
            label="Tìm kiếm"
            clearable
            hide-details
            outlined
            dense
          ></v-text-field
        ></v-col>
      </v-row>
    </template>
    <template slot="main_content">
      <CustomTable
        :headers="headers"
        :items="tableData"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        :loading="loading"
        loading-text="Đang tải dữ liệu ..."
        :pageCount="pageCount"
        @change-page="changePage"
        classPaging="pt-2"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-avatar color="indigo" size="28">
            <img
              v-if="item && item.url_image"
              :src="imageEndpoint + item.url_image"
              alt="John"
            />
            <span style="color: white" v-else-if="item && item.name">{{
              item.name.charAt(0).toUpperCase()
            }}</span>
            <v-icon v-else dark>mdi-account</v-icon>
          </v-avatar>
          <span class="ml-3" style="font-size: 16px">{{ item.name }}</span>
        </template>
        <template v-slot:[`item.role`]="{ item }">
          <v-list style="background-color: rgba(0, 0, 0, 0)">
            <v-list-item-title>{{
              item.role ? item.role.description : ""
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              item.role ? item.role.name : ""
            }}</v-list-item-subtitle>
          </v-list>
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-chip v-if="!item.active" color="pink" dark small>
            Ngừng hoạt động
          </v-chip>
          <v-chip v-else color="success" dark small> Đang hoạt động </v-chip>
        </template>
        <template v-slot:[`item.deleted`]="{ item }">
          <v-chip v-if="item.deleted" color="red" dark small> Đã xóa </v-chip>
          <v-chip v-else color="primary" dark small> Chưa xóa </v-chip>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="textColor">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0">
              <v-list-item @click="editMenu(item)">
                <v-list-item-title>
                  <v-icon class="mr-2"> mdi-pencil </v-icon>
                  Cập nhật</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                v-if="item.active && item.id !== USER.id"
                @click="deactivateUser(item)"
              >
                <v-list-item-title>
                  <v-icon class="mr-2"> mdi-lock-outline </v-icon>
                  Hủy kích hoạt</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                v-if="!item.active && item.id !== USER.id"
                @click="activeUser(item)"
              >
                <v-list-item-title>
                  <v-icon class="mr-2"> mdi-lock-open-outline </v-icon>
                  Kích hoạt tài khoản</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                v-if="isSysAdmin && !item.deleted"
                @click="deleteUser(item)"
              >
                <v-list-item-title>
                  <v-icon class="mr-2"> mdi-delete-variant </v-icon>
                  Xóa người dùng</v-list-item-title
                >
              </v-list-item>

              <v-list-item
                v-if="isSysAdmin && item.deleted"
                @click="restoreUser(item)"
              >
                <v-list-item-title>
                  <v-icon class="mr-2"> mdi-backup-restore</v-icon>
                  Khôi phục</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </CustomTable>
    </template>
    <create-edit
      ref="menuForm"
      @on-done="getDataUsers"
      :roles="roles"
    ></create-edit>
  </ListLayout>
</template>

<script>
import CreateEdit from "./create-edit";
import { debounce } from "lodash";
import { listUser, activeUser, hideUser, phucHoiUser } from "@/api/user";
import { getRoles } from "@/api/menu";
export default {
  components: { CreateEdit },
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      options: {},
      trangThais: [
        { name: "Đang hoạt đông", value: true },
        { name: "Ngừng hoạt đông", value: false },
      ],
      trangThaiXoas: [
        { name: "Đã xóa", value: true },
        { name: "Chưa xóa", value: false },
      ],
      totalDesserts: 0,
      tableData: [],
      btnLoading: false,
      menu: {},
      loading: false,
      trang_thai_xoa: null,
      search: "",
      roleId: null,
      imageEndpoint: process.env.VUE_APP_BASE,
      roles: [],
      trang_thai: null,
      isSysAdmin: false,
      headers: [
        // { text: "STT", width: "100", sortable: false, value: "stt" },
        { text: "Người dùng", value: "name", sortable: false },
        {
          text: "Tên đăng nhập",
          align: "start",
          sortable: false,
          value: "user_name",
        },
        {
          text: "E-Mail",
          align: "start",
          sortable: false,
          value: "email",
        },
        { text: "Quyền", value: "role" },
        { text: "Trạng thái", value: "active" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  computed: {
    USER() {
      return this.$store.state.User.me;
    },
  },
  created() {
    this.getDataUsers();
    this.getAllRoles();
  },
  mounted() {
    this.isSysAdmin = false;
    if (this.USER.role.code == "sysadmin") {
      this.isSysAdmin = true;
      this.headers.push({ text: "Đã xóa", value: "deleted" });
    }
  },
  watch: {
    search: debounce(async function (val) {
      this.loading = true;
      let data = await listUser({
        page: 1,
        perPage: this.itemsPerPage,
        search: val,
        role_id: this.roleId,
        trang_thai: this.trang_thai,
        trang_thai_xoa: this.trang_thai_xoa,
      });
      this.loading = false;
      this.tableData = data.data;
      this.pageCount = data.last_page;
    }, 300),
  },
  methods: {
    async getDataUsers() {
      this.loading = true;
      let data = await listUser({
        page: this.page,
        perPage: this.itemsPerPage,
        role_id: this.roleId,
        search: this.search,
        trang_thai: this.trang_thai,
        trang_thai_xoa: this.trang_thai_xoa,
      });
      this.tableData = data.data;
      this.loading = false;
      this.pageCount = data.last_page;
    },
    changePage(val) {
      this.page = val;
      this.getDataUsers();
    },
    editMenu(menu) {
      this.$refs.menuForm.showFormEdit(menu);
    },
    createMenu() {
      this.$refs.menuForm.showFormAdd();
    },
    async activeUser(user) {
      this.$confirmBox.show({
        title: "Kích hoạt tài khoản người dùng",
        width: 500,
        body:
          "Bạn có chắc chắn muốn kích hoạt khoản người dùng " +
          "<strong>" +
          user.name +
          " ?" +
          "</strong><br>" +
          "<strong>" +
          user.name +
          "</strong>" +
          " có thể đăng nhập và sử dụng hệ thống",
        action: () => activeUser({ userId: user.id, active: true }),
        onDone: this.getDataUsers,
      });
    },
    async deactivateUser(user) {
      this.$confirmBox.show({
        title: "Hủy kích hoạt tài khoản người dùng",
        width: 480,
        body:
          "Bạn có chắc chắn muốn hủy kích hoạt tài khoản của người dùng " +
          "<strong>" +
          user.name +
          " ?" +
          "</strong><br>" +
          "<strong>" +
          user.name +
          "</strong>" +
          " sẽ không đăng nhập hệ thống",
        action: () => activeUser({ userId: user.id, active: false }),
        onDone: this.getDataUsers,
      });
    },
    async deleteUser(user) {
      this.$confirmBox.show({
        title: "Xóa - Ẩn tài khoản người dùng, nhân viên",
        width: 550,
        body:
          "Bạn chắc chắn muốn ẩn tài khoản người dùng " +
          "<strong>" +
          user.name +
          " ?" +
          "</strong><br>" +
          "Người dùng này chỉ hiển thị đối với phân quyền SysAdmin",
        action: () => hideUser({ user_id: user.id }),
        onDone: this.getDataUsers,
      });
    },
    async restoreUser(user) {
      this.$confirmBox.show({
        title: "Phục hồi tài khoản - nhân viên đã xóa",
        width: 550,
        body:
          "Bạn chắc chắn muốn phục hồi tài khoản người dùng " +
          "<strong>" +
          user.name +
          " ?" +
          "</strong><br>" +
          "Người dùng này sẽ hiển thị trên hệ thống với tất cả phân quyền",
        action: () => phucHoiUser({ user_id: user.id }),
        onDone: this.getDataUsers,
      });
    },
    async changeRoleSearch() {
      this.page = 1
      this.getDataUsers();
    },

    async getAllRoles() {
      let data = await getRoles();
      this.roles = data;
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  .btn-add {
    display: none;
  }
}
.full-height {
  height: 100%;
}
</style>
