var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"800"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.edit ? "CẬP NHẬT THÔNG TIN NGƯỜI DÙNG" : "THÊM MỚI NGƯỜI DÙNG"))]),_c('br'),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"xs":"12","sm":"5","md":"5"}},[_c('v-img',{attrs:{"height":"200px","src":_vm.masterialPic}},[_c('v-card-text',[_c('v-layout',{staticClass:"align-center",attrs:{"column":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"180"},on:{"click":_vm.uploadAvatar}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.form.url_image ? _vm.imageEndpoint + _vm.form.url_image : _vm.avatarNone}}),_c('v-btn',{staticStyle:{"position":"absolute","top":"130px","right":"40px"},attrs:{"color":"blue","fab":"","x-small":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pencil")])],1)],1)]}}])},[_c('span',[_vm._v("Upload Ảnh đại diện")])])],1),_c('input',{ref:"upload-image",staticStyle:{"display":"none"},attrs:{"name":"avatar","type":"file"},on:{"change":function($event){return _vm.handleUpload($event)}}})],1)],1),_c('v-layout',{staticClass:"align-center",attrs:{"column":""}},[_c('v-text-field',{attrs:{"placeholder":"E-Mail","prepend-inner-icon":"mdi-email","rules":_vm.formValidate.email},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('v-text-field',{attrs:{"placeholder":"Tên đăng nhập","prepend-inner-icon":"mdi-account","rules":_vm.formValidate.user_name},model:{value:(_vm.form.user_name),callback:function ($$v) {_vm.$set(_vm.form, "user_name", $$v)},expression:"form.user_name"}})],1)],1),_c('v-col',{attrs:{"xs":"12","sm":"7","md":"7"}},[_c('div',{staticClass:"label-form"},[_vm._v("Tên người dùng")]),_c('v-text-field',{attrs:{"placeholder":"Nhập tên người dùng","rules":_vm.formValidate.name,"outlined":"","dense":"","prepend-inner-icon":"mdi-account"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('div',{staticClass:"label-form"},[_vm._v("Quyền quản trị")]),_c('v-select',{attrs:{"items":_vm.roles,"rules":_vm.formValidate.role_id,"outlined":"","dense":"","item-text":"name","item-value":"id","prepend-inner-icon":"mdi-wrench","placeholder":"Chọn quyền quản trị"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name + " - " + item.description)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('v-spacer'),_c('v-chip',{attrs:{"text-color":"white","color":"green","small":""}},[_vm._v(_vm._s(item.description))])],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.form.role_id),callback:function ($$v) {_vm.$set(_vm.form, "role_id", $$v)},expression:"form.role_id"}}),_c('br'),_c('div',{staticClass:"label-form",staticStyle:{"font-weight":"bold"}},[_vm._v("Mật khẩu đăng nhập")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Mật khẩu","outlined":"","dense":"","rules":_vm.edit ? [] : _vm.formValidate.password,"type":"password","prepend-inner-icon":"mdi-lock"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Nhập lại mật khẩu","outlined":"","type":"password","rules":[_vm.confirmPassword],"dense":"","prepend-inner-icon":"mdi-lock"},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("Đóng")]),(!_vm.edit)?_c('v-btn',{attrs:{"loading":_vm.btnLoading,"color":"primary"},on:{"click":_vm.addUser}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Thêm mới ")],1):_c('v-btn',{attrs:{"loading":_vm.btnLoading,"color":"primary"},on:{"click":_vm.updateUser}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Cập nhật ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }